function get_verification_status(){
    return $("#inquiry_verification_status").val()
}

function set_verification_status(status){
    $("#inquiry_verification_status").val(status)
    if (status === "Verified") 
    { 
        $("#verification_status").show();
        $("#verification_status_partial").hide();
        return;
    }
    if (status === "Partial")
    { 
        $("#verification_status").hide();
        $("#verification_status_partial").show();
        return;
    }
    // default
    $("#verification_status").hide();
    $("#verification_status_partial").hide();
}

function update_address(json){
    console.log(json);
        
        // set status
    set_verification_status(json['verification_status']);
    // set address_validation_event
    $("#inquiry_address_validation_event_id").val(json['address_validation_event_id'])
    
    inquiry_name = $("#inquiry_name").val();

    if (get_verification_status() === "ValidationError")
    {
        // Fetch from input fields
        address1 = $("#inquiry_address1").val();
        address2 = $("#inquiry_address2").val();
        address3 = $("#inquiry_address3").val();
        address4 = $("#inquiry_address4").val();
        postal_code = $("#inquiry_postal_code").val();
        locality = $("#inquiry_locality").val(); 
        country = $("#inquiry_country").val();
                    
        full_address = name + "\n" + address1 + "\n";
        if (address2.length > 0) { full_address = full_address.concat(address2+ "\n");}
        if (address3.length > 0) { full_address = full_address.concat(address3+ "\n");}
        if (address4.length > 0) { full_address = full_address.concat(address4+ "\n");}
        full_address = full_address.concat(postal_code + "\n" + locality + "\n" + country + "\n");
    }
    else
    {
        // Fetch from json
        address1 = json['address1'];
        address2 = json['address2'];
        address3 = json['address3'];
        address4 = json['address4'];
        postal_code = json['postal_code'];
        locality = json['locality'];
        country = json['country'];
    
            $("#inquiry_address1").val(address1);
            $("#inquiry_address2").val(address2);
            $("#inquiry_address3").val(address3);
            $("#inquiry_address4").val(address4);
            $("#inquiry_postal_code").val(postal_code);
            $("#inquiry_locality").val(locality);
            
        full_address = inquiry_name + "\n" + address1 + "\n";
        if (address2.length > 0) { full_address = full_address.concat(address2+ "\n");}
        if (address3.length > 0) { full_address = full_address.concat(address3+ "\n");}
        if (address4.length > 0) { full_address = full_address.concat(address4+ "\n");}
        full_address = full_address.concat(country + "\n");

    }
                            
    // Construct address
    $("#inquiry_message").val(full_address);  		
}

function validate_address(){
        data = { verification_status: get_verification_status(),
                 address_validation_event_id: $("#inquiry_address_validation_event_id").val(),
                 address1: $("#inquiry_address1").val(), 
                 address2: $("#inquiry_address2").val(), 
                 address3: $("#inquiry_address3").val(), 
                 address4: $("#inquiry_address4").val(), 
             postal_code: $("#inquiry_postal_code").val(),
                 locality: $("#inquiry_locality").val(),
                 country: $("#inquiry_country").val() };
    
    // Empty message (will be filled in update_address callback)
    $("#inquiry_message").val("...");  
                 
     $.ajax({
            url: "/request/validate_address", 
            method: "get",
            dataType: "json",
            data: data,
            cache: false,
            success: function(res){ update_address(res);} 
    });
 };

 $(document).on("turbo:load", function(){

    if($("#wizard").length){

        $("#wizard").steps({
            headerTag: "h2",
            bodyTag: "div",
            transitionEffect: "none",
            enableFinishButton: true,
            enablePagination: true,
            enableAllSteps: false,
            titleTemplate: "#title#",
            cssClass: "tabcontrol form-minimal row",
            
            onStepChanging: function (event, currentIndex, newIndex)
                {
                // Allways allow previous action even if the current form is not valid!
                if (currentIndex > newIndex)
                {
                    return true;
                }
                
                // Needed in some cases if the user went back (clean up)
                if (currentIndex < newIndex)
                {
                    // To remove error styles
                    $(this).find(".body:eq(" + newIndex + ") label.error").remove();
                    $(this).find(".body:eq(" + newIndex + ") .error").removeClass("error");
                }
                
                // Country check
                if (newIndex === 2)
                {
                    country = $("#inquiry_country").val();
                    if (country === $('#form-labels').data('not-a-country'))
                    {
                        //console.log("Invalid country");
                        $("#inquiry_country").val("");
                        return false;
                    }
                    
                    // Add rule to country field
                    $("#inquiry_country").rules( "add", { minlength: 3 } );

                }
                
                // Fill message field
                if (newIndex === 3)
                {
                                            
                    $(this).validate({ ignore : ":disabled,:hidden"});
                        
                        if ($("#new_inquiry").valid())
                        {
                            // Validate address 
                        validate_address();								       
                        return true;
                    }
                    return false;
                }	
                
                return $("#new_inquiry").valid();
                },
            onFinishing: function (event, currentIndex)
            {	
                    // Allow empty email address
                email = $("#inquiry_email").val();
                    if (email.length == 0) {$("#inquiry_email").val("notprovided@example.com"); }
                
                    // validate
                $(this).validate().settings.ignore = ":disabled";
                return $("#new_inquiry").valid();
            },
            onFinished: function (event, currentIndex)
            {	
                    //alert("Submitted!")
                    $("#new_inquiry").trigger("submit");
            },
            onInit: function(event, currentIndex)
            {
                // When changes happen, we don't know if it is still valid
                $( ".verify" ).on("change", function() {
                    //console.log("input changed.");
                    set_verification_status("");
                });
                
            },
            
            /* Labels */
            labels: {
                next: $('#form-labels').data('next'),
                previous: $('#form-labels').data('previous'),
                finish: $('#form-labels').data('finish') 
                }
        });
       


        jQuery.extend(jQuery.validator.messages, {
            required: $('#form-labels').data('required'),
            email: $('#form-labels').data('email'),
            minlength: $('#form-labels').data('minlength')
        });

    } // end if
});