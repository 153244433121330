
	$(document).on("turbo:load", function() { 
		"use strict";
		$( ".neko-mega-menu" ).wrapInner( "<div></div>");





	/*
	|--------------------------------------------------------------------------
	| Revolution slider fullscreen
	|--------------------------------------------------------------------------
	*/	

	if($('#rs-slider-fullscreen').length){

		$('#rs-slider-fullscreen').revolution(
		{
			debugMode: false,
			sliderType:'standard',
			jsFileLocation:'js-plugins/rs-plugin/js/',
			sliderLayout:'fullscreen',
			fullScreenOffsetContainer:'.menu-header',
			responsiveLevels: [1200,1025,768,480],
			gridwidth:1140,
			gridheight:720,	
			delay:6500,
			spinner:'spinner4',
			lazyType: 'none',
			navigation: {
				onHoverStop:"off",
				touch:{
					touchenabled:"on",
					swipe_threshold: 75,
					swipe_min_touches: 1,
					swipe_direction: "horizontal",
					drag_block_vertical: false
				},
				arrows: {
					style:"zeus",
					enable:true,
					hide_onmobile:true,
					hide_under:480,
					hide_onleave:true,
					hide_delay:200,
					hide_delay_mobile:1200,
					tmp: '<div class="tp-title-wrap"></div>',
					left: {
						h_align: "left",
						v_align: "center",
						h_offset: 10,
						v_offset: 0
					},
					right: {
						h_align: "right",
						v_align: "center",
						h_offset: 10,
						v_offset: 0
					}
				},
				bullets: {
					enable: true,
					hide_onmobile: false,
					style: 'zeus',
					hide_onleave: false,
					direction: 'horizontal',
					tmp: '<span class="tp-bullet-title"></span>',
					h_align: 'left',
					v_align: 'bottom',
					h_offset: 20,
					v_offset: 30,
					space: 5
				}
			}



		});

}


/*
	|--------------------------------------------------------------------------
	| Revolution slider fullwidth 1
	|--------------------------------------------------------------------------
	*/	

	if($('#rs-slider-fullwidth-1').length){

		$('#rs-slider-fullwidth-1').revolution(
		{
			debugMode: false,
			sliderType:'standard',
			jsFileLocation:'js-plugins/rs-plugin/js/',
			sliderLayout:'fullwidth',
			responsiveLevels: [1200,1025,768,480],
			delay:3000,
			gridheight:720,	
			spinner:'spinner2',
			lazyType: 'none',
			navigation: {
				onHoverStop:"off",
				touch:{
					touchenabled:"on",
					swipe_threshold: 75,
					swipe_min_touches: 1,
					swipe_direction: "horizontal",
					drag_block_vertical: false
				},
				arrows: {
					style:"gyges",
					enable:true,
					hide_onmobile:true,
					hide_under:480,
					hide_onleave:true,
					hide_delay:200,
					hide_delay_mobile:1200,
					tmp: '<div class="tp-title-wrap"></div>',
					left: {
						h_align: "left",
						v_align: "center",
						h_offset: 10,
						v_offset: 0
					},
					right: {
						h_align: "right",
						v_align: "center",
						h_offset: 10,
						v_offset: 0
					}
				},
				bullets: {
					enable: true,
					hide_onmobile: true,
					hide_onleave: false,
					direction: 'horizontal',
					tmp: '<span class="tp-bullet-title"></span>',
					h_align: 'center',
					v_align: 'bottom',
					h_offset: 0,
					v_offset: 30,
					space: 5
				}
			}
		});
	}

	/*
	|--------------------------------------------------------------------------
	| Revolution slider kenburn
	|--------------------------------------------------------------------------
	*/	

	if($('#rs-slider-kenburn').length){

		$('#rs-slider-kenburn').revolution(
		{
			debugMode: false,
			sliderType:'standard',
			jsFileLocation:'js-plugins/rs-plugin/js/',
			sliderLayout:'fullwidth',
			responsiveLevels: [1200,1025,768,480],
			delay:5000,
			gridheight:720,	
			spinner:'spinner2',
			lazyType: 'none',
			navigation: {
				onHoverStop:"off",
				touch:{
					touchenabled:"on",
					swipe_threshold: 75,
					swipe_min_touches: 1,
					swipe_direction: "horizontal",
					drag_block_vertical: false
				},
				arrows: {
					style:"gyges",
					enable:true,
					hide_onmobile:true,
					hide_under:480,
					hide_onleave:true,
					hide_delay:200,
					hide_delay_mobile:1200,
					tmp: '<div class="tp-title-wrap"></div>',
					left: {
						h_align: "left",
						v_align: "center",
						h_offset: 10,
						v_offset: 0
					},
					right: {
						h_align: "right",
						v_align: "center",
						h_offset: 10,
						v_offset: 0
					}
				},
				bullets: {
					enable: true,
					hide_onmobile: true,
					tmp: '<span class="tp-bullet-title"></span>',
					hide_onleave: false,
					direction: 'horizontal',
					h_align: 'center',
					v_align: 'bottom',
					h_offset: 0,
					v_offset: 30,
					space: 5
				}
			}



		});

}



$('#menu-opener').on( "click", function(event) {
	event.preventDefault();

	var $finalHeight = ($('#features-section-menu').height() == 500 ) ? 0 : 500;

	$('#features-section-menu > div').animate({
		height: $finalHeight },
		300, 'easeInOutCubic',function() {
			/* stuff to do after animation is complete */
		});

	if( 0 == $finalHeight){
		$(this).html('<i class="icon-plus"></i> open <span>Neko bloc quick menu</span>');
	}else{
		$(this).html('<i class="icon-minus"></i> close <span>Neko bloc quick menu</span>');
	}

});


$(".copy-button-simple").each(function(index, el) {

	var $this = $(this),
	client = new ZeroClipboard( $this );

	client.on( "ready", function( event ) {
			//alert( "ZeroClipboard SWF is ready!" );


			client.on( 'copy', function(event) {
				var data = $this.parent().next('section')[0].outerHTML;
				var final_data = data.replace(/<!--[\s\S]*?-->/g, '');
				event.clipboardData.clearData();
				event.clipboardData.setData('text/plain', final_data);
				//event.clipboardData.setData('text/html', $data);
			});

			client.on( "aftercopy", function( event ) {
				alert("Bloc copied successfully");
			});
		});	
	
});

$(".copy-button-hidden-source").each(function(index, el) {

	var $this = $(this),
	client = new ZeroClipboard( $this );

	client.on( "ready", function( event ) {
			//alert( "ZeroClipboard SWF is ready!" );


			client.on( 'copy', function(event) {
				var data = $this.next('textarea').text();

				
				event.clipboardData.clearData();
				event.clipboardData.setData('text/plain', data);
				//event.clipboardData.setData('text/html', $data);
			});

			client.on( "aftercopy", function( event ) {
				alert("Bloc copied successfully");
			});
		});	
	
});


}); //End Doc Ready




/*
|--------------------------------------------------------------------------
| Revolution slider fullwidth
|--------------------------------------------------------------------------
*/	

function gaSSDSLoad(acct) {
	"use strict";  
	var gaJsHost = (("https:" === document.location.protocol) ? "https://ssl." : "http://www."),
	pageTracker,
	s;
	s = document.createElement('script');
	s.src = gaJsHost + 'google-analytics.com/ga.js';
	s.type = 'text/javascript';
	s.onloadDone = false;
	function init () {
		pageTracker = _gat._getTracker(acct);
		pageTracker._trackPageview();
	}
	s.onload = function () {
		s.onloadDone = true;
		init();
	};
	s.onreadystatechange = function() {
		if (('loaded' === s.readyState || 'complete' === s.readyState) && !s.onloadDone) {
			s.onloadDone = true;
			init();
		}
	};
	document.getElementsByTagName('head')[0].appendChild(s);
}
