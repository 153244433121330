
import ahoy from 'ahoy.js'
var myPlayer = null; 

function create_video_player(){
    if($('#video-player').length){
        // avoid multiple instances
        //if (myPlayer) { return; };

        console.log("Creating video player");
        myPlayer = jQuery("#video-player").YTPlayer();
            
        myPlayer.on("YTPTime",function(e){
            var currentTime = e.time;
            if (currentTime==5){
                console.log("Video. Time: " + currentTime)
                ahoy.track("VideoPlayed", {slug: $("#video-slug").data("slug") });
                }

        });

    }
}

$(document).on('turbo:load', function(){
    create_video_player()
})

