$(document).on('turbo:load', function(){
	if($('#newsletter-signup').length){
  	$('.hide-newsletter-signup').on("click", function(){
      $.ajax({
			url: "/refinery/campaigns/hide_newsletter_signup", method: "post",
			cache: false,
			success: function(r){ $("#newsletter-signup").hide();} 
		  });
	});
	  
  }
})