import ahoy from 'ahoy.js';

$(document).on("turbo:load", function(){
	//var conversion_url = $(".download div a").attr("href");    
	if ($(".download").length){
    $(".download").on("click", function() {
        var slug = $(".download").data("slug");
        ahoy.track("Download", { slug: slug, item: "Product" });
    });
  }
});