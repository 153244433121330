

function toggle_font_size()
{
	if (font_size < 20)
	{
		set_font_size( font_size + 2, true );
		set_font_size_quote( font_size_quote + 2, true );
	}
	else
	{
		set_font_size( 14, true );
		set_font_size_quote( 14, true );
	}
}


function set_font_size( size, post )
{
	console.log("Setting font size: " + size);

	font_size = size;

	var body = $("div.text-left.resizable");
	$(body).css( "font-size", size + "px" );
	$(body).css( "line-height",  Math.floor(size * 1.5) + "px" );

	if (post)
	{
		localStorage.setItem( "fontSize", font_size );
	}
}

function set_font_size_quote( size, post )
{
	font_size_quote = size;

	var body = $("div.text-left.resizable blockquote");
	$(body).css( "font-size", size + "px" );
	$(body).css( "line-height",  Math.floor(size * 1.5) + "px" );

	if (post)
	{
		localStorage.setItem( "fontSizeQuote", font_size_quote );
	}
}

function retrieve_with_default(key,value){
	result = localStorage.getItem( key );
	if (result == null){
		result = value;
		localStorage.setItem(key,value);
	}
	return parseInt(result);
}


$(document).on('turbo:load', function(){
	if ( $(".resizable").length ) {	
		var font_size = retrieve_with_default("fontSize", "16" );
		var font_size_quote = retrieve_with_default("fontSizeQuote", "16");

		set_font_size( font_size, false );
		set_font_size_quote( font_size_quote, false );
	
		$(".toggleable").on("click", function(){
			toggle_font_size();
			return false;
		});
	}
});

