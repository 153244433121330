function set_language( language_code )
{
	switch(language_code){
		case "en":
			document.location = "https://www.thetrumpet.com";
			break;
		case "de":
			document.location = "https://www.dieposaune.de";
			break;
		case "nl":
			document.location = "http://www.thetrumpet.nl";
			break;	
		case "fr":
			document.location = "http://www.latrompette.fr";
			break;
		case "es":
			document.location = "http://www.latrompeta.es";
			break;	
		case "nb":
			document.location = "https://www.trompeten.co";
			break;		
		case "pt":
			document.location = "https://www.atrombeta.pt";
			break;
		case "af":
			document.location = "https://www.diebasuin.com";
			break;		
		case "fi":
			document.location = "http://www.pasuuna.com";
			break;	
		case "it":
			document.location = "https://www.latromba.org";
			break;	}

};

// make global
window.set_language = set_language;