function refresh_cart_icon(){
 	$.ajax({
    		url: "/literature/products/refresh_cart_icon", method: "post",
    		cache: false,
    		success: function(html){ $("#shopping-cart-icon").html(html);} 
    });
 };

 function refresh_shopping_cart(){
	if($("#shopping-cart").length){

		$.ajax({
			url: "/literature/products/refresh_cart", method: "post",
			cache: false,
			success: function(html){ 
				set_shopping_cart_html(html);
				refresh_cart_icon();
				} 
		}); 

		// and update icon
		setTimeout(refresh_cart_icon(), 100);
	}
 };

function set_shopping_cart_html(html){
	$("#shopping-cart").html(html);
};



$(document).on('turbo:load', refresh_shopping_cart());
$(window).on('popstate', function(event){ refresh_shopping_cart() });
