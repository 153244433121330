
//console.log('Hello from neko.js webpack')

require("jquery-ui")
require("jqueryui.autocomplete") 
require("jquery.steps")
require("jquery.validate")
require("ytplayer/jquery.mb.YTPlayer")

require("autocomplete-rails")

require("neko-framework/external-plugins/appear/jquery.appear")
require("neko-framework/external-plugins/easing/jquery.easing.1.3")
require("neko-framework/external-plugins/elevatezoom/jquery.elevatezoom")
require("neko-framework/external-plugins/fullpage/jquery.fullPage")
//require("neko-framework/external-plugins/magnific-popup/jquery.magnific-popup.js") 
require("neko-framework/external-plugins/owl-carousel/owl.carousel-MODIFIED")
require("neko-framework/external-plugins/swiper/dist/idangerous.swiper")
require("neko-framework/external-plugins/swiper/dist/idangerous.swiper.scrollbar")
require("neko-framework/external-plugins/toucheffects/toucheffects")
require("neko-framework/external-plugins/bootstrap/bootstrap")
require("neko-framework/neko-framework")

require("video")
require("fontsize")
require("set_language")
require("refresh_cart")
//= require activecampaign
require("js-cookie")
require("eu_gdpr")
require("hide_newsletter_signup")
require("custom")

require("download")
require("inquiry")

