
var windowIsTurbolinked = 'Turbo' in window;

function grantGoogleConsent() {
  gtag('consent', 'update', {
    'ad_user_data': 'granted',
    'ad_personalization': 'granted',
    'ad_storage': 'granted',
    'analytics_storage': 'granted'
  });
}

function updateGoogleConsent(json) {
  gtag('consent', 'update', json);
}

$(document).on("turbo:load", function(){ 
  if($('#cookies-eu-modal').length){
      $('#cookies-eu-modal').modal({ backdrop: 'static', keyboard: false })

      $('.js-cookies-eu-performance').on("click", function(event){
        $.ajax({
        url: "/performance_consent", method: "post",
        data: { performance_consent: event.target.checked },
        cache: false
        });
      });

      $('.js-cookies-eu-targeting').on("click", function(event){
        $.ajax({
        url: "/targeting_consent", method: "post",
        data: { targeting_consent: event.target.checked },
        cache: false
        });
      });

      $('.js-cookies-eu-functionality').on("click", function(event){
        $.ajax({
        url: "/functionality_consent", method: "post",
        data: { functionality_consent: event.target.checked },
        cache: false
        });
      });

      $('.js-cookies-eu-acceptall').on("click", function(event){
          $.ajax({
            url: "/accept_all", method: "post",
            cache: false,
            dataType: "json",
            success: function(data) {
              console.log("Cookies saved. " + JSON.stringify(data));
              updateGoogleConsent(data);

              // clear turbolinks cache so cookie banner does not reappear
              windowIsTurbolinked && window.Turbo.cache.clear();
            }
          });
        });

        $('.js-cookies-eu-save').on("click", function(event){
          $.ajax({
            url: "/save_consent", method: "post",
            cache: false,
            dataType: "json",
            success: function(data) {
              console.log("Cookies saved. " + JSON.stringify(data));
              updateGoogleConsent(data);

              // clear turbolinks cache so cookie banner does not reappear
              windowIsTurbolinked && window.Turbo.cache.clear();
            }
          });
        });
  };
})
